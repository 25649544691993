import { Notify } from 'vant';
import { v4 as uuid4 } from 'uuid';
import send from '@/lib/send';
import store from '@/store';

const request = send({
  host: process.env.VUE_APP_REQUEST_HOST,
  timeout: 600000,
  headers: {
    'X-Auth-Token': localStorage.getItem('authToken') || '',
  },

  beforeRequest(config) {
    if (config.loading) {
      config.uuid = uuid4();
      store.dispatch('changeLoading', config.uuid);
    }

    return config;
  },

  requestErrorHandler(error) {
    Notify('网络异常，请检查您的网络');
    return Promise.reject(error);
  },

  afterResponse(response) {
    const passCode = response.config.passCode || [];

    if (response.config.uuid === store.state.loading) {
      store.dispatch('changeLoading', '');
    }

    if (['200', ...passCode].includes(response.data.code)) {
      return response.data;
    } else {
      if (!response.config.cancelErrorHandler) {
        Notify(response.data.message || '出错了...');
      }

      return Promise.reject(response.data.message);
    }
  },

  responseErrorHandler(error) {
    Notify('网络异常，请重试');

    store.dispatch('changeLoading', '');

    return Promise.reject(error);
  },
});

export default request;
