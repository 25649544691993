import axios from 'axios';
import JSONBig from 'json-bigint';

export default function send(option) {
  const request = axios.create({
    baseURL: option.host,

    timeout: option.timeout,

    headers: option.headers,

    transformResponse: [
      (data) => {
        try {
          if (typeof data === 'string') {
            return JSON.parse(JSON.stringify(JSONBig.parse(data)));
          } else {
            throw new Error();
          }
        } catch (_) {
          return data;
        }
      },
    ],
  });

  request.interceptors.request.use(
    option.beforeRequest,
    option.requestErrorHandler
  );

  request.interceptors.response.use(
    option.afterResponse,
    option.responseErrorHandler
  );

  return request;
}
